@import "../../styles/includes/i-typography";

.aaaem-tabs {
    &__tablist {
        display: flex;
        flex-direction: row;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            display: none;
        }
    }

    &__tab {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 50%;
        margin: 0;
        border: 0;
        height: 49px;
        font-size: 18px;
        line-height: 1.67;
        padding: 0;
        border: none;
        color: var(--emu-semantic-colors-primary-purple-100);

        &:first-child {
            border: 0;

            & li:first-child {
                margin: 0;
            }
        }

        .tab-icon {
            display: none;
        }

        a {
            @include txt-12-20R;

            background-color: var(--emu-common-colors-white);
            color: var(--emu-common-colors-dark-brown-500);
            letter-spacing: 1.8px;
            text-transform: uppercase;
            position: relative;
            border: 1px solid var(--emu-common-colors-dark-brown-500);
        }

        a[aria-selected='true'] {
            border: 1px solid var(--emu-common-colors-dark-brown-500);
            background-color: var(--emu-common-colors-dark-brown-500);
            color: var(--emu-common-colors-white);

            &:hover,
            &:active,
            &:focus {
                background-color: var(--emu-common-colors-dark-brown-500);
                color: var(--emu-common-colors-white);
            }
        }

        a,
        a[aria-selected='true'] {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    &__tabpanels {
        display: flex;
        flex-direction: row-reverse;
    }

    &__tabpanel {
        flex: 1 1 auto;
        overflow: visible;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            display: block;
        }

        &:first-child {
            height: calc(100vh - 233px); //233px = 150 margin top of aaaem-tabs--ICLmaps + 83 height footer

            @include mq($emu-semantic-sizing-breakpoints-large) {
                max-width: calc(100% - 460px);
                overflow: hidden;
                height: unset;
            }
        }

        &:last-child {
            margin-bottom: 83px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                max-width: 460px;
                margin-bottom: 0;
            }
        }

        & .container.responsivegrid {
            height: 100%;
        }
    }

    &__horizontal-ui > ul:first-child li {
        margin: 0;
    }

    &.aaaem-tabs--ICLmaps {
        margin: 150px 0 0; // the value of margin top mentioned on line #79
        position: relative;

        & .aaaem-tabs__tablist {
            position: absolute;
            top: -49px;
            width: 100%;
        }

        @include mq($emu-semantic-sizing-breakpoints-large) {
            margin: 77px 0 55px 0;
        }
    }
}
