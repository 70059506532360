// General mixins for Roboto and Beatrice fonts
@mixin roboto-font($font-size, $line-height: null) {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: $font-size;
    line-height: if($line-height, $line-height, $font-size * 1.333); // Default line-height if not provided
}

@mixin beatrice-font($font-size, $line-height: null) {
    font-family: 'Beatrice-Light', sans-serif;
    font-weight: 300;
    font-size: $font-size;
    line-height: if($line-height, $line-height, $font-size * 1.5); // Default line-height if not provided
}

// Roboto Fonts
@mixin txt-12-16R {
    @include roboto-font(12px, 16px);
}

@mixin txt-12-20R {
    @include roboto-font(12px, 20px);
}

@mixin txt-14-20R {
    @include roboto-font(14px, 20px);
}

@mixin txt-16-20R {
    @include roboto-font(16px, 20px);
}

@mixin txt-18-20R {
    @include roboto-font(18px, 20px);
}

@mixin txt-20-20R {
    @include roboto-font(20px, 20px);
}

// Beatrice Fonts
@mixin txt-14-18B {
    @include beatrice-font(14px, 18px);
}

@mixin txt-22-22B {
    @include beatrice-font(22px, 22px);
}

@mixin txt-24-34B {
    @include beatrice-font(24px, 34px);
}

@mixin txt-32-44B {
    @include beatrice-font(32px, 44px);
}

@mixin txt-32-32B {
    @include beatrice-font(32px, 32px);
}
