@font-face {
    font-display: swap;
    font-family: 'Beatrice-Bold';
    font-weight: 300 700;
    src: url('./assets/fonts/Beatrice/Beatrice-Bold.woff2') format('woff2'),
        url('./assets/fonts/Beatrice/Beatrice-Bold.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Beatrice-Light';
    font-weight: 300 700;
    src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
        url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Beatrice-Regular';
    font-weight: 300 700;
    src: url('./assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2'),
        url('./assets/fonts/Beatrice/Beatrice-Regular.woff') format('woff');
}
