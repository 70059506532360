@import '../../styles/includes/i-typography';

.cmp-form-options {
    &--checkbox {
        @include txt-14-20R;

        padding: 12px 22px;
        background-color: var(--emu-common-colors-white);
        border-radius: 6px;
        color: var(--emu-common-colors-dark-brown-500);
        letter-spacing: 1.8px;
        text-transform: uppercase;
        border: 1px solid var(--emu-common-colors-dark-brown-500);
    }

    &__field--checkbox:not(:checked) + span:after {
        opacity: 0;
        transform: scale(0);
    }

    &__field--checkbox:checked,
    &__field--checkbox:not(:checked) {
        width: 14px;
        height: 14px;
        opacity: 0;
        position: absolute;
        top: 12px;
        left: 19px;
    }

    &__field--checkbox:checked+span,
    &__field--checkbox:not(:checked)+span {
        cursor: pointer;
        display: block;
        padding-left: 28px;
        position: relative;
    }

    &__field--checkbox+span:before,
    &__field--checkbox:not(:checked)+span:before,
    &__field--checkbox:checked+span:after,
    &__field--checkbox:not(:checked)+span:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 6px;
    }

    &__field--checkbox+span:before,
    &__field--checkbox:not(:checked)+span:before {
        background: var(--emu-common-colors-white);
        border: 1px solid var(--emu-common-colors-dark-brown-500);
    }

    &__field--checkbox:checked+span:after,
    &__field--checkbox:not(:checked)+span:after {
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2011%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.7812%201.21875C15.0625%201.53125%2015.0625%202%2014.7812%202.28125L6.53125%2010.5312C6.21875%2010.8438%205.75%2010.8438%205.46875%2010.5312L1.21875%206.28125C0.90625%206%200.90625%205.53125%201.21875%205.25C1.5%204.9375%201.96875%204.9375%202.25%205.25L5.96875%208.96875L13.7188%201.21875C14%200.9375%2014.4688%200.9375%2014.75%201.21875H14.7812Z%22%20fill%3D%22%23492728%22%2F%3E%3C%2Fsvg%3E');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 14px;
        transition: all .2s ease;
    }
}
