@import "../../styles/includes/i-typography";

.aaaem-text {
    & p {
        @include txt-16-20R;

        color: var(--emu-common-colors-black);
        margin-top: 22px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-top: 44px;
        }

        & a {
            text-decoration: underline;
        }
    }

    &--disclaimer {
        margin-bottom: 22px;

        & p {
            font-size: 11px;
            line-height: 13.5px;
            margin-top: 16px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                margin-top: 22px;
            }
        }
    }
}
