@import "../../styles/includes/i-typography";

.emu-location-services {
    display: block;
    font-family: 'Roboto', sans-serif;
    margin-top: 22px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-top: 44px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            gap: 22px;
        }
    }

    &__location {
        &-input-wrapper {
            position: relative;
            flex: 1;
            margin-bottom: 0;

            &::before {
                content: '';
                position: absolute;
                display: inline-block;
                background-size: contain;
                background-repeat: no-repeat;
                z-index: 10;
                top: 14px;
                left: 16px;
                width: 16px;
                height: 16px;
                background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2017%2017%22%20fill%3D%22currentColor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%206.5C12%204.71875%2011.0312%203.09375%209.5%202.1875C7.9375%201.28125%206.03125%201.28125%204.5%202.1875C2.9375%203.09375%202%204.71875%202%206.5C2%208.3125%202.9375%209.9375%204.5%2010.8438C6.03125%2011.75%207.9375%2011.75%209.5%2010.8438C11.0312%209.9375%2012%208.3125%2012%206.5ZM11.0312%2011.625C9.90625%2012.5%208.5%2013%207%2013C3.40625%2013%200.5%2010.0938%200.5%206.5C0.5%202.9375%203.40625%200%207%200C10.5625%200%2013.5%202.9375%2013.5%206.5C13.5%208.03125%2012.9688%209.4375%2012.0938%2010.5625L16.2812%2014.7188C16.5625%2015.0312%2016.5625%2015.5%2016.2812%2015.7812C15.9688%2016.0938%2015.5%2016.0938%2015.2188%2015.7812L11.0312%2011.625Z%22%20fill%3D%22currentColor%22%2F%3E%3C%2Fsvg%3E');
            }
        }

        &-input-error,
        &-null-error {
            @include txt-12-20R;

            margin-top: 10px;
            color: var(--emu-common-colors-black);
            letter-spacing: 0.216px;
        }

        &-input {
            @include txt-14-20R;

            width: 100%;
            height: 44px;
            background-color: var(--emu-common-colors-white);
            border-radius: 6px;
            border: 1px solid var(--emu-common-colors-dark-brown-500);
            padding: 0 22px 0 36px;
            color: var(--emu-common-colors-dark-brown-500);
            letter-spacing: 1.8px;
            text-transform: uppercase;

            &::placeholder {
                color: var(--emu-common-colors-grey-130);
            }
        }
    }

    &__search-submit-cta {
        @include txt-14-20R;

        background-color: var(--emu-common-colors-dark-brown-500);
        border-radius: 6px;
        padding: 0 7px;
        cursor: pointer;
        display: inline-block;
        background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2017%2017%22%20fill%3D%22%23FFFFFF%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%206.5C12%204.71875%2011.0312%203.09375%209.5%202.1875C7.9375%201.28125%206.03125%201.28125%204.5%202.1875C2.9375%203.09375%202%204.71875%202%206.5C2%208.3125%202.9375%209.9375%204.5%2010.8438C6.03125%2011.75%207.9375%2011.75%209.5%2010.8438C11.0312%209.9375%2012%208.3125%2012%206.5ZM11.0312%2011.625C9.90625%2012.5%208.5%2013%207%2013C3.40625%2013%200.5%2010.0938%200.5%206.5C0.5%202.9375%203.40625%200%207%200C10.5625%200%2013.5%202.9375%2013.5%206.5C13.5%208.03125%2012.9688%209.4375%2012.0938%2010.5625L16.2812%2014.7188C16.5625%2015.0312%2016.5625%2015.5%2016.2812%2015.7812C15.9688%2016.0938%2015.5%2016.0938%2015.2188%2015.7812L11.0312%2011.625Z%22%20fill%3D%22%23FFFFFF%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat;
        background-position: center;
        color: transparent;
        max-height: 44px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            padding-left: 44px;
            padding-right: 16px;
            background-position: 16px center;
            color: var(--emu-common-colors-white);
        }
    }

    &__current-location-btn {
        @include txt-16-20R;

        position: relative;
        margin-top: 12px;
        color: var(--emu-common-colors-dark-brown-500);
        text-decoration: underline;
        padding-left: 36px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-top: 16px;
        }

        &::before {
            content: '';
            position: absolute;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 10;
            top: -2px;
            left: 0;
            width: 24px;
            height: 24px;
            background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22currentColor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%3E%3Cpath%20id%3D%22Vector%22%20d%3D%22M12%2016C14.2091%2016%2016%2014.2091%2016%2012C16%209.79086%2014.2091%208%2012%208C9.79086%208%208%209.79086%208%2012C8%2014.2091%209.79086%2016%2012%2016Z%22%20fill%3D%22%23492728%22%20stroke%3D%22transparent%22%20stroke-width%3D%222%22%2F%3E%3Cpath%20id%3D%22Vector_2%22%20d%3D%22M13%204.069V2H11V4.069C9.2403%204.29368%207.60497%205.09617%206.35057%206.35057C5.09617%207.60497%204.29368%209.2403%204.069%2011H2V13H4.069C4.29335%2014.7598%205.09574%2016.3953%206.3502%2017.6498C7.60466%2018.9043%209.24017%2019.7066%2011%2019.931V22H13V19.931C14.7599%2019.7068%2016.3955%2018.9045%2017.65%2017.65C18.9045%2016.3955%2019.7068%2014.7599%2019.931%2013H22V11H19.931C19.7066%209.24017%2018.9043%207.60466%2017.6498%206.3502C16.3953%205.09574%2014.7598%204.29335%2013%204.069ZM12%2018C8.691%2018%206%2015.309%206%2012C6%208.691%208.691%206%2012%206C15.309%206%2018%208.691%2018%2012C18%2015.309%2015.309%2018%2012%2018Z%22%20fill%3D%22%23492728%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%220.5%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        }
    }
}
