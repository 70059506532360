html {
    -webkit-text-size-adjust: 100%;
}

:root {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    font-family: var(--emu-semantic-font-families-body);
    line-height: 1.4;
    text-align: left;

    // Adobe adds a negative margin, need to overwrite. Do not remove.
    .xf-content-height {
        margin: inherit;
    }
}

body {
    -webkit-overflow-scrolling: touch;
}

* {
    outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
    box-sizing: border-box;
}

.cmp-image__image,
image {
    max-width: 100%;
    width: auto;
}

button,
input,
optgroup,
select,
textarea {
    font: inherit;
}

ul[class],
ol[class] {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul[class],
ol[class] {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

a,
a:visited,
a:hover,
a:active {
    color: inherit;
    text-decoration: inherit;
}

img {
    max-width: 100%;
    display: block;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

.new,
.hide {
    display: none;
}
