@import "../../styles/includes/i-typography";

.modal {
    &-content {
        [data-component='modal'] & {
            display: flex;
            width: 350px;
            height: 300px;
            padding: 44px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 22px;
            border: 1px solid var(--emu-common-colors-dark-brown-500);

            @include mq($emu-semantic-sizing-breakpoints-large) {
                width: 600px;
            }

            & .modal-content__header h1 {
                @include txt-32-44B;

                color: var(--emu-common-colors-black);
            }

            & .modal-content__message p {
                @include txt-20-20R;

                padding: 16px;
                color: var(--emu-common-colors-black);
            }

            & .main-close {
                @include txt-32-32B;

                color: var(--emu-common-colors-dark-brown-500);
            }
        }
    }

    &-wrapper {
        [data-component='modal'] & {
            background-color: rgba(255, 255, 255, 0.5);
        }
    }
}
