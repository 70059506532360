@import "../../styles/includes/i-typography";

.aaaem-container {
    margin: 0;
    padding: 0;
    background-color: transparent;

    .aaaem-tabs__tabpanel & {
        background-color: transparent;
        padding: 0;
        margin: 0;
    }

    &__header {
        .cmp-experiencefragment--header & {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            gap: 29px;
            padding: 12px 22px;
            background-color: var(--emu-common-colors-dark-brown-500);
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 99;
            max-height: 77px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                padding: 12px 44px;
            }
        }

        & .aaaem-button {
            @include txt-16-20R;

            color: var(--emu-common-colors-white);
            letter-spacing: 0.55px;
            text-decoration-line: underline;
            text-transform: uppercase;
            background-color: transparent;
            padding: 0;

            &:active,
            &:visited,
            &:hover {
                color: var(--emu-common-colors-white);
            }
        }

        & .aaaem-image__logo a img {
            height: 55px;
        }
    }

    &__footer {
        .cmp-experiencefragment--footer & {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            border-top: 3px solid var(--emu-common-colors-dark-brown-500);
            padding: 16px 22px;
            gap: 8px;
            width: 100%;
            z-index: 99;
            position: fixed;
            bottom: 0;
            background-color: var(--emu-common-colors-white);
            max-height: 83px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                flex-direction: row;
                align-items: center;
                padding: 16px 44px;
                max-height: 55px;
            }
        }

        & .aaaem-text p {
            @include txt-16-20R;

            margin-top: 0;
            color: var(--emu-common-colors-black);
            letter-spacing: 0.55px;
            text-transform: uppercase;
        }

        & .aaaem-button {
            @include txt-14-20R;

            color: var(--emu-common-colors-black);
            letter-spacing: 0;
            text-decoration-line: underline;
            text-transform: uppercase;
            padding: 0;
            display: block;
        }

        &--multipleLinks {
            .cmp-experiencefragment--footer & {
                padding: 4px 22px;
                gap: 4px;
                align-items: flex-start;
                height: 100%;
                justify-content: space-between;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                    padding: 6px 44px;
                    max-height: 55px;
                    align-items: center;
                }
            }

            & .aaaem-button,
            & .aaaem-text p {
                font-size: 9px;
                line-height: 9px;
                display: flex;
                align-items: flex-end;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                    font-size: 11px;
                    line-height: 11px;
                }
            }

            & .aaaem-button {
                min-height: 12px;
            }

            & div.text {
                min-width: 110px;
            }
        }

        &Left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 29px;

            .aaaem-container__footer--multipleLinks & {
                flex-direction: row;
                row-gap: 4px;
                column-gap: 10px;
                justify-content: flex-start;
                align-items: end;
            }
        }
    }

    &__50 {
        max-width: 400px;
        box-sizing: content-box;
        padding: 0 22px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            max-width: 485px;
            padding: 0 44px;
        }
    }

    &__btnContainer {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 16px;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            margin-top: 22px;
        }
    }

    &__bgImage {
        min-height: 350px;
        background-color: var(--emu-common-colors-red-110);
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 77px;
        height: calc(100vh - 160px);

        @include mq($emu-semantic-sizing-breakpoints-large) {
            background-image: url('../../assets/images/bgImg.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 556px;
            height: calc(100vh - 132px);
        }

        &--pink {
            background-color: var(--emu-common-colors-red-120);
        }

        .cq-Editable-dom--container & {
            height: fit-content;
        }
    }

    &__googleMaps {
        height: 100%;

        @include mq($emu-semantic-sizing-breakpoints-large) {
            width: 100%;
            max-width: calc(100% - 460px);
            position: fixed;
            top: 0;
            left: 460px;
        }

        & .googlemaps {
            height: 100%;
            margin: 0;

            @include aem-editor-view() {
                min-height: auto;
            }

            @include mq($emu-semantic-sizing-breakpoints-large) {
                position: fixed;
                top: 0;
                left: 460px;
                max-width: calc(100% - 460px);
                width: 100%;
                margin-top: 77px;

                &.not-sticky {
                    position: absolute;
                    bottom: 0;
                    top: initial;
                    left: 0;
                    max-width: 100%;
                }
            }
        }

        & .options {
            position: fixed;
            bottom: 113px;
            left: 50%;
            z-index: 999;
            transform: translateX(-50%);

            @include mq($emu-semantic-sizing-breakpoints-large) {
                position: absolute;
                top: 32px;
                bottom: auto;
            }
        }

        & .clinics__results {
            display: none;
        }
    }
}
