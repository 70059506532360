@import "../../styles/includes/i-typography";

.emu-title {
    margin-bottom: 0;
    margin-top: 22px;

    @include mq($emu-semantic-sizing-breakpoints-large) {
        margin-top: 0;
    }

    &__text {
        @include txt-24-34B;

        color: var(--emu-common-colors-black);

        @include mq($emu-semantic-sizing-breakpoints-large) {
            font-size: 32px;
            line-height: 44px;
        }
    }
}
