@import "../../styles/includes/i-typography";

.aaaem-button {
    @include txt-14-20R;

    display: inline-block;
    padding: 8px 16px;
    border: 0;
    border-radius: 6px;
    background-color: var(--emu-common-colors-white);
    color: var(--emu-common-colors-dark-brown-500);
    letter-spacing: 1.8px;
    text-transform: uppercase;

    &:visited,
    &:hover,
    &:active {
        color: var(--emu-common-colors-dark-brown-500);
    }

    &:active,
    &:focus,
    &:focus-visible {
        outline: 1px solid var(--emu-common-colors-light-blue-110);
    }
}
