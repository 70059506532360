.emu-colors-bg__transparent {
    background-color: var(--emu-semantic-colors-none) !important;
}

.emu-colors-bg__light {
    background-color: var(--emu-semantic-colors-surface-light) !important;
}

.emu-colors-bg__dark {
    background-color: var(--emu-semantic-colors-surface-dark) !important;
}

.emu-colors-text__light {
    color: var(--emu-semantic-colors-text-light) !important;
}

.emu-colors-text__dark {
    color: var(--emu-semantic-colors-text-dark) !important;
}

.emu-colors-primary__light {
    color: var(--emu-semantic-colors-primary-light) !important;
}

.emu-colors-primary__dark {
    color: var(--emu-semantic-colors-primary-dark) !important;
}

.emu-colors-secondary__light {
    color: var(--emu-semantic-colors-secondary-light) !important;
}

.emu-colors-secondary__dark {
    color: var(--emu-semantic-colors-secondary-dark) !important;
}

.emu-colors-tertiary__light {
    color: var(--emu-semantic-colors-tertiary-light) !important;
}

.emu-colors-tertiary__dark {
    color: var(--emu-semantic-colors-tertiary-dark) !important;
}

.emu-colors-error__light {
    color: var(--emu-semantic-colors-error-light) !important;
}

.emu-colors-error__dark {
    color: var(--emu-semantic-colors-error-dark) !important;
}

.emu-colors-background__light {
    undefined: var(--emu-semantic-colors-background-light) !important;
}

.emu-colors-background__dark {
    undefined: var(--emu-semantic-colors-background-dark) !important;
}

.emu-font__body {
    font-family: var(--emu-semantic-font-families-body) !important;
}

.emu-font__heading {
    font-family: var(--emu-semantic-font-families-heading) !important;
}

.emu-font__mono {
    font-family: var(--emu-semantic-font-families-mono) !important;
}

.emu-spacing-mt__xxs {
    margin-top: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-mr__xxs {
    margin-right: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-mb__xxs {
    margin-bottom: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-ml__xxs {
    margin-left: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-mx__xxs {
    margin-left: var(--emu-semantic-sizing-xxs) !important;
    margin-right: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-my__xxs {
    margin-top: var(--emu-semantic-sizing-xxs) !important;
    margin-bottom: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-m__xxs {
    margin: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-pt__xxs {
    padding-top: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-pr__xxs {
    padding-right: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-pb__xxs {
    padding-bottom: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-pl__xxs {
    padding-left: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-px__xxs {
    padding-left: var(--emu-semantic-sizing-xxs) !important;
    padding-right: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-py__xxs {
    padding-top: var(--emu-semantic-sizing-xxs) !important;
    padding-bottom: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-p__xxs {
    padding: var(--emu-semantic-sizing-xxs) !important;
}

.emu-spacing-mt__xs {
    margin-top: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-mr__xs {
    margin-right: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-mb__xs {
    margin-bottom: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-ml__xs {
    margin-left: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-mx__xs {
    margin-left: var(--emu-semantic-sizing-xs) !important;
    margin-right: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-my__xs {
    margin-top: var(--emu-semantic-sizing-xs) !important;
    margin-bottom: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-m__xs {
    margin: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-pt__xs {
    padding-top: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-pr__xs {
    padding-right: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-pb__xs {
    padding-bottom: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-pl__xs {
    padding-left: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-px__xs {
    padding-left: var(--emu-semantic-sizing-xs) !important;
    padding-right: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-py__xs {
    padding-top: var(--emu-semantic-sizing-xs) !important;
    padding-bottom: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-p__xs {
    padding: var(--emu-semantic-sizing-xs) !important;
}

.emu-spacing-mt__sm {
    margin-top: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-mr__sm {
    margin-right: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-mb__sm {
    margin-bottom: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-ml__sm {
    margin-left: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-mx__sm {
    margin-left: var(--emu-semantic-sizing-small) !important;
    margin-right: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-my__sm {
    margin-top: var(--emu-semantic-sizing-small) !important;
    margin-bottom: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-m__sm {
    margin: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-pt__sm {
    padding-top: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-pr__sm {
    padding-right: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-pb__sm {
    padding-bottom: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-pl__sm {
    padding-left: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-px__sm {
    padding-left: var(--emu-semantic-sizing-small) !important;
    padding-right: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-py__sm {
    padding-top: var(--emu-semantic-sizing-small) !important;
    padding-bottom: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-p__sm {
    padding: var(--emu-semantic-sizing-small) !important;
}

.emu-spacing-mt__md {
    margin-top: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-mr__md {
    margin-right: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-mb__md {
    margin-bottom: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-ml__md {
    margin-left: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-mx__md {
    margin-left: var(--emu-semantic-sizing-medium) !important;
    margin-right: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-my__md {
    margin-top: var(--emu-semantic-sizing-medium) !important;
    margin-bottom: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-m__md {
    margin: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-pt__md {
    padding-top: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-pr__md {
    padding-right: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-pb__md {
    padding-bottom: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-pl__md {
    padding-left: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-px__md {
    padding-left: var(--emu-semantic-sizing-medium) !important;
    padding-right: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-py__md {
    padding-top: var(--emu-semantic-sizing-medium) !important;
    padding-bottom: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-p__md {
    padding: var(--emu-semantic-sizing-medium) !important;
}

.emu-spacing-mt__lg {
    margin-top: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-mr__lg {
    margin-right: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-mb__lg {
    margin-bottom: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-ml__lg {
    margin-left: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-mx__lg {
    margin-left: var(--emu-semantic-sizing-large) !important;
    margin-right: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-my__lg {
    margin-top: var(--emu-semantic-sizing-large) !important;
    margin-bottom: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-m__lg {
    margin: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-pt__lg {
    padding-top: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-pr__lg {
    padding-right: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-pb__lg {
    padding-bottom: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-pl__lg {
    padding-left: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-px__lg {
    padding-left: var(--emu-semantic-sizing-large) !important;
    padding-right: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-py__lg {
    padding-top: var(--emu-semantic-sizing-large) !important;
    padding-bottom: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-p__lg {
    padding: var(--emu-semantic-sizing-large) !important;
}

.emu-spacing-mt__xl {
    margin-top: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-mr__xl {
    margin-right: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-mb__xl {
    margin-bottom: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-ml__xl {
    margin-left: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-mx__xl {
    margin-left: var(--emu-semantic-sizing-xl) !important;
    margin-right: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-my__xl {
    margin-top: var(--emu-semantic-sizing-xl) !important;
    margin-bottom: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-m__xl {
    margin: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-pt__xl {
    padding-top: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-pr__xl {
    padding-right: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-pb__xl {
    padding-bottom: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-pl__xl {
    padding-left: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-px__xl {
    padding-left: var(--emu-semantic-sizing-xl) !important;
    padding-right: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-py__xl {
    padding-top: var(--emu-semantic-sizing-xl) !important;
    padding-bottom: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-p__xl {
    padding: var(--emu-semantic-sizing-xl) !important;
}

.emu-spacing-mt__xxl {
    margin-top: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-mr__xxl {
    margin-right: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-mb__xxl {
    margin-bottom: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-ml__xxl {
    margin-left: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-mx__xxl {
    margin-left: var(--emu-semantic-sizing-xxl) !important;
    margin-right: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-my__xxl {
    margin-top: var(--emu-semantic-sizing-xxl) !important;
    margin-bottom: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-m__xxl {
    margin: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-pt__xxl {
    padding-top: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-pr__xxl {
    padding-right: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-pb__xxl {
    padding-bottom: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-pl__xxl {
    padding-left: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-px__xxl {
    padding-left: var(--emu-semantic-sizing-xxl) !important;
    padding-right: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-py__xxl {
    padding-top: var(--emu-semantic-sizing-xxl) !important;
    padding-bottom: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-p__xxl {
    padding: var(--emu-semantic-sizing-xxl) !important;
}

.emu-spacing-mx__auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
