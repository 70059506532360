@import '../../styles/includes/i-typography';

.clinic-popup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
    margin-right: 20px;

    &__heading {
        @include txt-14-18B;

        color: var(--emu-common-colors-black);
    }

    &__description {
        @include txt-12-16R;

        color: var(--emu-common-colors-black);
    }

    &__buttons-container {
        display: flex;
        flex-direction: column-reverse;
        gap: 8px;
    }

    &__link {
        &-website,
        &-phone {
            @include txt-12-16R;

            color: var(--emu-common-colors-black);
            text-decoration: underline;

            &:hover,
            &:active,
            &:focus {
                color: var(--emu-common-colors-black);
                text-decoration: underline;
            }
        }

        &-clinic {
            display: none;
        }
    }
}

// Part of the CSS overlay of the clinic-popup
.gm-style-iw-chr {
    position: absolute;
    right: 0;

    & button {
        opacity: 1;
        width: 24px;
        height: 24px;

        & span {
            margin: 0;
        }
    }
}
