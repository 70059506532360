
/**
 * ** 🚨 DO NOT EDIT THIS FILE DIRECTLY!
 * If you want to make edits make them in each appropriate token JSON file.
 */

$emu-semantic-colors-none: rgba(0,0,0,0) !default;
$emu-semantic-colors-surface-light: #FEFCF8 !default;
$emu-semantic-colors-surface-dark: #000000 !default;
$emu-semantic-colors-surface-modal-backdrop: rgba(0, 0, 0, 0.4) !default;
$emu-semantic-colors-actions-primary-light: #FF1D15 !default;
$emu-semantic-colors-actions-primary-dark: #CC0700 !default;
$emu-semantic-colors-actions-on-primary-light: #000000 !default;
$emu-semantic-colors-actions-on-primary-dark: #FFFFFF !default;
$emu-semantic-colors-error: #ad2300 !default;
$emu-semantic-colors-text-light: #470D4A !default;
$emu-semantic-colors-text-dark: #FFFFFF !default;
$emu-semantic-colors-text-teal: #00B0B9 !default;
$emu-semantic-colors-foreground-light: #000000 !default;
$emu-semantic-colors-foreground-dark: #FFFFFF !default;
$emu-semantic-colors-primary-purple-100: #470d4a !default;
$emu-semantic-colors-primary-purple-600: rgba(71, 13, 74, 0.6) !default;
$emu-semantic-colors-secondary-purple-100: #94368d !default;
$emu-semantic-colors-secondary-purple-200: #38093F !default;
$emu-semantic-colors-secondary-purple-light-100: #f4e4ed !default;
$emu-semantic-colors-secondary-purple-light-200: #ece3f3 !default;
$emu-semantic-colors-secondary-grey-100: #d9ccd5 !default;
$emu-semantic-colors-secondary-grey-200: #f4f4f4 !default;
$emu-semantic-colors-secondary-magenta-100: #f4e4ec !default;
$emu-semantic-colors-contrast-purple-100: #dccbe3 !default;
$emu-semantic-colors-contrast-purple-200: #753a76 !default;
$emu-semantic-colors-contrast-purple-300: #481948 !default;
$emu-semantic-colors-contrast-purple-400: #753a76 !default;
$emu-semantic-colors-contrast-purple-500: #230023 !default;
$emu-semantic-colors-contrast-purple-600: #ece3ed !default;
$emu-semantic-colors-contrast-purple-700: #ede5f1 !default;
$emu-semantic-colors-contrast-purple-800: #efe6f6 !default;
$emu-semantic-colors-contrast-white-100: #fefcf8 !default;
$emu-semantic-colors-contrast-grey-100: #cbc9c5 !default;
$emu-semantic-colors-contrast-grey-200: #58585a !default;
$emu-semantic-colors-transparent-magenta-100: rgba(155, 24, 137, 0.1) !default;
$emu-semantic-colors-transparent-magenta-200: rgba(71, 13, 74, 0.2) !default;
$emu-semantic-colors-transparent-silver-100: rgba(162, 163, 165, 0.1) !default;
$emu-semantic-colors-transparent-purple-100: rgba(173, 128, 208, 0.2) !default;
$emu-semantic-colors-transparent-purple-200: rgba(220, 203, 227, 0.5) !default;
$emu-semantic-colors-transparent-purple-300: rgba(148, 54, 141, 0.1) !default;
$emu-semantic-colors-transparent-purple-400: rgba(155, 24, 137, 0.1) !default;
$emu-semantic-colors-transparent-purple-900: rgba(71, 13, 74, 0.9) !default;
$emu-semantic-colors-transparent-purple-light: rgba(71, 13, 74, 0.1) !default;
$emu-semantic-colors-transparent-white-50: rgb(255, 255, 255, 0.05) !default;
$emu-semantic-colors-transparent-white-100: rgb(255, 255, 255, 0.1) !default;
$emu-semantic-colors-transparent-white-300: rgba(255, 255, 255, 0.3) !default;
$emu-semantic-colors-transparent-white-400: rgba(255, 255, 255, 0.4) !default;
$emu-semantic-colors-transparent-white-500: rgba(255, 255, 255, 0.5) !default;
$emu-semantic-colors-transparent-white-600: rgba(255, 255, 255, 0.6) !default;
$emu-semantic-colors-transparent-white-700: rgba(255, 255, 255, 0.73) !default;
$emu-semantic-colors-transparent-white-800: rgba(255, 255, 255, 0.8) !default;
$emu-semantic-colors-transparent-grey-100: rgb(161, 163, 165, 0.1) !default;
$emu-semantic-colors-transparent-grey-200: rgba(161, 163, 165, 0.5) !default;
$emu-semantic-colors-transparent-black-200: rgba(0, 0, 0, 0.2) !default;
$emu-semantic-colors-transparent-black-300: rgba(0, 0, 0, 0.3) !default;
$emu-semantic-colors-transparent-black-600: rgba(0, 0, 0, 0.6) !default;
$emu-semantic-font-weight-bold: 700 !default;
$emu-semantic-font-weight-light: 300 !default;
$emu-semantic-font-weight-black: 900 !default;
$emu-semantic-font-weight-regular: 400 !default;
$emu-semantic-font-families-body: Satoshi-Variable !default;
$emu-semantic-font-families-heading: Gambetta-Variable !default;
$emu-semantic-font-families-body-assistant: Assistant !default;
$emu-semantic-font-families-heading-assistant: Assistant !default;
$emu-semantic-font-families-mono: Courier !default;
$emu-semantic-spacing-none: 0px !default;
$emu-semantic-spacing-horizontal: 8px !default;
$emu-semantic-spacing-vertical: 8px !default;
$emu-semantic-spacing-one-line-height-wide: 22px !default;
$emu-semantic-spacing-one-line-height-narrow: 17px !default;
$emu-semantic-font-sizes-narrow-medium: 12px !default;
$emu-semantic-font-sizes-narrow-large: 16.97px !default;
$emu-semantic-font-sizes-narrow-xl: 24px !default;
$emu-semantic-font-sizes-narrow-xxl: 41px !default;
$emu-semantic-font-sizes-narrow-xxxl: 61px !default;
$emu-semantic-font-sizes-wide-medium: 16px !default;
$emu-semantic-font-sizes-wide-large: 22.62px !default;
$emu-semantic-font-sizes-wide-xl: 41px !default;
$emu-semantic-font-sizes-wide-xxl: 61px !default;
$emu-semantic-font-sizes-wide-xxxl: 91px !default;
$emu-semantic-line-heights-narrow-large: 17px !default;
$emu-semantic-line-heights-narrow-medium: 17px !default;
$emu-semantic-line-heights-narrow-xl: 30px !default;
$emu-semantic-line-heights-narrow-xxl: 45.1px !default;
$emu-semantic-line-heights-narrow-xxxl: 59.78px !default;
$emu-semantic-line-heights-wide-large: 22px !default;
$emu-semantic-line-heights-wide-medium: 22px !default;
$emu-semantic-line-heights-wide-xl: 45px !default;
$emu-semantic-line-heights-wide-xxl: 70.15px !default;
$emu-semantic-line-heights-wide-xxxl: 90.09px !default;
$emu-semantic-border-radius-xs: 4px !default;
$emu-semantic-border-radius-small: 8px !default;
$emu-semantic-border-radius-medium: 16px !default;
$emu-semantic-border-radius-large: 32px !default;
$emu-semantic-border-radius-none: 0px !default;
$emu-semantic-border-width-thin: 1px !default;
$emu-semantic-border-width-medium: 2px !default;
$emu-semantic-border-width-thick: 4px !default;
$emu-semantic-border-width-none: 0px !default;
$emu-semantic-outline-width-thin: thin !default;
$emu-semantic-outline-width-medium: medium !default;
$emu-semantic-outline-width-thick: thick !default;
$emu-semantic-outline-width-unset: unset !default;
$emu-semantic-sizing-none: 0px !default;
$emu-semantic-sizing-xxs: 1px !default;
$emu-semantic-sizing-xs: 8px !default;
$emu-semantic-sizing-small: 16px !default;
$emu-semantic-sizing-medium: 32px !default;
$emu-semantic-sizing-large: 64px !default;
$emu-semantic-sizing-xl: 128px !default;
$emu-semantic-sizing-xxl: 256px !default;
$emu-semantic-sizing-breakpoints-small: 390px !default;
$emu-semantic-sizing-breakpoints-medium: 576px !default;
$emu-semantic-sizing-breakpoints-large: 768px !default;
$emu-semantic-sizing-breakpoints-x-large: 992px !default;
$emu-semantic-sizing-breakpoints-xx-large: 1200px !default;
$emu-semantic-sizing-one-line-height-wide: 22px !default;
$emu-semantic-sizing-one-line-height-narrow: 17px !default;
$emu-semantic-sizing-content-max-width: 1140px !default;
$emu-semantic-typography-narrow-headings-xxxl: 400 61px/59.78px Gambetta-Variable !default;
$emu-semantic-typography-narrow-headings-xxl: 400 41px/45.1px Gambetta-Variable !default;
$emu-semantic-typography-narrow-headings-xl: 400 24px/30px Gambetta-Variable !default;
$emu-semantic-typography-narrow-headings-large: 400 12px/17px Gambetta-Variable !default;
$emu-semantic-typography-narrow-headings-medium: 700 12px/17px Gambetta-Variable !default;
$emu-semantic-typography-narrow-headings-assistant-xxxl: 400 61px/59.78px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-xxl: 400 41px/45.1px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-xl: 400 24px/30px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-large: 400 12px/17px Assistant !default;
$emu-semantic-typography-narrow-headings-assistant-medium: 700 12px/17px Assistant !default;
$emu-semantic-typography-narrow-body-regular: 400 12px/17px Satoshi-Variable !default;
$emu-semantic-typography-narrow-body-assistant-regular: 400 12px/17px Assistant !default;
$emu-semantic-typography-wide-headings-xxxl: 400 91px/90.09px Gambetta-Variable !default;
$emu-semantic-typography-wide-headings-xxl: 400 61px/70.15px Gambetta-Variable !default;
$emu-semantic-typography-wide-headings-xl: 400 41px/45px Gambetta-Variable !default;
$emu-semantic-typography-wide-headings-large: 400 22.62px/22px Gambetta-Variable !default;
$emu-semantic-typography-wide-headings-medium: 700 16px/22px Gambetta-Variable !default;
$emu-semantic-typography-wide-headings-assistant-xxxl: 400 91px/90.09px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-xxl: 400 61px/70.15px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-xl: 400 41px/45px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-large: 400 22.62px/22px Assistant !default;
$emu-semantic-typography-wide-headings-assistant-medium: 700 16px/22px Assistant !default;
$emu-semantic-typography-wide-body-regular: 400 16px/22px Satoshi-Variable !default;
$emu-semantic-typography-wide-body-assistant-regular: 400 16px/22px Assistant !default;
$emu-semantic-other-z-index-language-navigation: 600 !default;

$semantic: (
  'semantic': (
    'colors': (
      'none': $emu-semantic-colors-none,
      'surface': (
        'light': $emu-semantic-colors-surface-light,
        'dark': $emu-semantic-colors-surface-dark,
        'modal-backdrop': $emu-semantic-colors-surface-modal-backdrop
      ),
      'actions': (
        'primary': (
          'light': $emu-semantic-colors-actions-primary-light,
          'dark': $emu-semantic-colors-actions-primary-dark
        ),
        'on-primary': (
          'light': $emu-semantic-colors-actions-on-primary-light,
          'dark': $emu-semantic-colors-actions-on-primary-dark
        )
      ),
      'error': $emu-semantic-colors-error,
      'text': (
        'light': $emu-semantic-colors-text-light,
        'dark': $emu-semantic-colors-text-dark,
        'teal': $emu-semantic-colors-text-teal
      ),
      'foreground': (
        'light': $emu-semantic-colors-foreground-light,
        'dark': $emu-semantic-colors-foreground-dark
      ),
      'primary': (
        'purple': (
          '100': $emu-semantic-colors-primary-purple-100,
          '600': $emu-semantic-colors-primary-purple-600
        )
      ),
      'secondary': (
        'purple': (
          '100': $emu-semantic-colors-secondary-purple-100,
          '200': $emu-semantic-colors-secondary-purple-200,
          'light': (
            '100': $emu-semantic-colors-secondary-purple-light-100,
            '200': $emu-semantic-colors-secondary-purple-light-200
          )
        ),
        'grey': (
          '100': $emu-semantic-colors-secondary-grey-100,
          '200': $emu-semantic-colors-secondary-grey-200
        ),
        'magenta': (
          '100': $emu-semantic-colors-secondary-magenta-100
        )
      ),
      'contrast': (
        'purple': (
          '100': $emu-semantic-colors-contrast-purple-100,
          '200': $emu-semantic-colors-contrast-purple-200,
          '300': $emu-semantic-colors-contrast-purple-300,
          '400': $emu-semantic-colors-contrast-purple-400,
          '500': $emu-semantic-colors-contrast-purple-500,
          '600': $emu-semantic-colors-contrast-purple-600,
          '700': $emu-semantic-colors-contrast-purple-700,
          '800': $emu-semantic-colors-contrast-purple-800
        ),
        'white': (
          '100': $emu-semantic-colors-contrast-white-100
        ),
        'grey': (
          '100': $emu-semantic-colors-contrast-grey-100,
          '200': $emu-semantic-colors-contrast-grey-200
        )
      ),
      'transparent': (
        'magenta': (
          '100': $emu-semantic-colors-transparent-magenta-100,
          '200': $emu-semantic-colors-transparent-magenta-200
        ),
        'silver': (
          '100': $emu-semantic-colors-transparent-silver-100
        ),
        'purple': (
          '100': $emu-semantic-colors-transparent-purple-100,
          '200': $emu-semantic-colors-transparent-purple-200,
          '300': $emu-semantic-colors-transparent-purple-300,
          '400': $emu-semantic-colors-transparent-purple-400,
          '900': $emu-semantic-colors-transparent-purple-900,
          'light': $emu-semantic-colors-transparent-purple-light
        ),
        'white': (
          '50': $emu-semantic-colors-transparent-white-50,
          '100': $emu-semantic-colors-transparent-white-100,
          '300': $emu-semantic-colors-transparent-white-300,
          '400': $emu-semantic-colors-transparent-white-400,
          '500': $emu-semantic-colors-transparent-white-500,
          '600': $emu-semantic-colors-transparent-white-600,
          '700': $emu-semantic-colors-transparent-white-700,
          '800': $emu-semantic-colors-transparent-white-800
        ),
        'grey': (
          '100': $emu-semantic-colors-transparent-grey-100,
          '200': $emu-semantic-colors-transparent-grey-200
        ),
        'black': (
          '200': $emu-semantic-colors-transparent-black-200,
          '300': $emu-semantic-colors-transparent-black-300,
          '600': $emu-semantic-colors-transparent-black-600
        )
      )
    ),
    'fontWeight': (
      'bold': $emu-semantic-font-weight-bold,
      'light': $emu-semantic-font-weight-light,
      'black': $emu-semantic-font-weight-black,
      'regular': $emu-semantic-font-weight-regular
    ),
    'fontFamilies': (
      'body': $emu-semantic-font-families-body,
      'heading': $emu-semantic-font-families-heading,
      'bodyAssistant': $emu-semantic-font-families-body-assistant,
      'headingAssistant': $emu-semantic-font-families-heading-assistant,
      'mono': $emu-semantic-font-families-mono
    ),
    'spacing': (
      'none': $emu-semantic-spacing-none,
      'horizontal': $emu-semantic-spacing-horizontal,
      'vertical': $emu-semantic-spacing-vertical,
      'oneLineHeight': (
        'wide': $emu-semantic-spacing-one-line-height-wide,
        'narrow': $emu-semantic-spacing-one-line-height-narrow
      )
    ),
    'fontSizes': (
      'narrow': (
        'medium': $emu-semantic-font-sizes-narrow-medium,
        'large': $emu-semantic-font-sizes-narrow-large,
        'xl': $emu-semantic-font-sizes-narrow-xl,
        'xxl': $emu-semantic-font-sizes-narrow-xxl,
        'xxxl': $emu-semantic-font-sizes-narrow-xxxl
      ),
      'wide': (
        'medium': $emu-semantic-font-sizes-wide-medium,
        'large': $emu-semantic-font-sizes-wide-large,
        'xl': $emu-semantic-font-sizes-wide-xl,
        'xxl': $emu-semantic-font-sizes-wide-xxl,
        'xxxl': $emu-semantic-font-sizes-wide-xxxl
      )
    ),
    'lineHeights': (
      'narrow': (
        'large': $emu-semantic-line-heights-narrow-large,
        'medium': $emu-semantic-line-heights-narrow-medium,
        'xl': $emu-semantic-line-heights-narrow-xl,
        'xxl': $emu-semantic-line-heights-narrow-xxl,
        'xxxl': $emu-semantic-line-heights-narrow-xxxl
      ),
      'wide': (
        'large': $emu-semantic-line-heights-wide-large,
        'medium': $emu-semantic-line-heights-wide-medium,
        'xl': $emu-semantic-line-heights-wide-xl,
        'xxl': $emu-semantic-line-heights-wide-xxl,
        'xxxl': $emu-semantic-line-heights-wide-xxxl
      )
    ),
    'borderRadius': (
      'xs': $emu-semantic-border-radius-xs,
      'small': $emu-semantic-border-radius-small,
      'medium': $emu-semantic-border-radius-medium,
      'large': $emu-semantic-border-radius-large,
      'none': $emu-semantic-border-radius-none
    ),
    'borderWidth': (
      'thin': $emu-semantic-border-width-thin,
      'medium': $emu-semantic-border-width-medium,
      'thick': $emu-semantic-border-width-thick,
      'none': $emu-semantic-border-width-none
    ),
    'outlineWidth': (
      'thin': $emu-semantic-outline-width-thin,
      'medium': $emu-semantic-outline-width-medium,
      'thick': $emu-semantic-outline-width-thick,
      'unset': $emu-semantic-outline-width-unset
    ),
    'sizing': (
      'none': $emu-semantic-sizing-none,
      'xxs': $emu-semantic-sizing-xxs,
      'xs': $emu-semantic-sizing-xs,
      'small': $emu-semantic-sizing-small,
      'medium': $emu-semantic-sizing-medium,
      'large': $emu-semantic-sizing-large,
      'xl': $emu-semantic-sizing-xl,
      'xxl': $emu-semantic-sizing-xxl,
      'breakpoints': (
        'small': $emu-semantic-sizing-breakpoints-small,
        'medium': $emu-semantic-sizing-breakpoints-medium,
        'large': $emu-semantic-sizing-breakpoints-large,
        'x-large': $emu-semantic-sizing-breakpoints-x-large,
        'xx-large': $emu-semantic-sizing-breakpoints-xx-large
      ),
      'oneLineHeight': (
        'wide': $emu-semantic-sizing-one-line-height-wide,
        'narrow': $emu-semantic-sizing-one-line-height-narrow
      ),
      'contentMaxWidth': $emu-semantic-sizing-content-max-width
    ),
    'typography': (
      'narrow': (
        'headings': (
          'xxxl': $emu-semantic-typography-narrow-headings-xxxl,
          'xxl': $emu-semantic-typography-narrow-headings-xxl,
          'xl': $emu-semantic-typography-narrow-headings-xl,
          'large': $emu-semantic-typography-narrow-headings-large,
          'medium': $emu-semantic-typography-narrow-headings-medium,
          'assistant': (
            'xxxl': $emu-semantic-typography-narrow-headings-assistant-xxxl,
            'xxl': $emu-semantic-typography-narrow-headings-assistant-xxl,
            'xl': $emu-semantic-typography-narrow-headings-assistant-xl,
            'large': $emu-semantic-typography-narrow-headings-assistant-large,
            'medium': $emu-semantic-typography-narrow-headings-assistant-medium
          )
        ),
        'body': (
          'regular': $emu-semantic-typography-narrow-body-regular,
          'assistant': (
            'regular': $emu-semantic-typography-narrow-body-assistant-regular
          )
        )
      ),
      'wide': (
        'headings': (
          'xxxl': $emu-semantic-typography-wide-headings-xxxl,
          'xxl': $emu-semantic-typography-wide-headings-xxl,
          'xl': $emu-semantic-typography-wide-headings-xl,
          'large': $emu-semantic-typography-wide-headings-large,
          'medium': $emu-semantic-typography-wide-headings-medium,
          'assistant': (
            'xxxl': $emu-semantic-typography-wide-headings-assistant-xxxl,
            'xxl': $emu-semantic-typography-wide-headings-assistant-xxl,
            'xl': $emu-semantic-typography-wide-headings-assistant-xl,
            'large': $emu-semantic-typography-wide-headings-assistant-large,
            'medium': $emu-semantic-typography-wide-headings-assistant-medium
          )
        ),
        'body': (
          'regular': $emu-semantic-typography-wide-body-regular,
          'assistant': (
            'regular': $emu-semantic-typography-wide-body-assistant-regular
          )
        )
      )
    ),
    'other': (
      'zIndex': (
        'languageNavigation': $emu-semantic-other-z-index-language-navigation
      )
    )
  )
);
