@import '../../styles/includes/i-typography';

.emu-icl-feed {
    &__search {
        &Block {
            display: flex;
            flex-direction: column;
            padding: 44px 16px 0 16px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                padding-top: 22px;
            }
        }

        &Cont {
            &Select {
                position: absolute;
                top: 46px;
                left: 0;
                width: 100%;
                z-index: 19;

                &--none {
                    display: none;
                }

                & a {
                    @include txt-12-16R;

                    cursor: pointer;
                    background-color: var(--emu-common-colors-white);
                    border: 1px solid var(--emu-common-colors-dark-brown-500);
                    width: 100%;
                    padding: 16px;
                    display: inline-block;
                    color: var(--emu-common-colors-dark-brown-500);
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    border-top: 0;
                    z-index: 9;
                    position: relative;

                    &:last-of-type {
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        outline: 1px solid var(--emu-common-colors-light-blue-110);
                    }

                    &.emu-icl-feed__searchOption {
                        background-color: var(--emu-common-colors-grey-110);
                    }
                }
            }

            &Opts {
                margin-top: 16px;
                display: flex;
                flex-direction: row;
                gap: 10px;
                flex-wrap: wrap;
            }

            &Concern {
                position: relative;
                width: 100%;
            }

            &Clinic {
                flex: 1;
                position: relative;

                @include mq($emu-semantic-sizing-breakpoints-large) {
                    min-width: 130px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 17px;
                    left: 16px;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    z-index: 9;
                    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2017%2017%22%20fill%3D%22currentColor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%206.5C12%204.71875%2011.0312%203.09375%209.5%202.1875C7.9375%201.28125%206.03125%201.28125%204.5%202.1875C2.9375%203.09375%202%204.71875%202%206.5C2%208.3125%202.9375%209.9375%204.5%2010.8438C6.03125%2011.75%207.9375%2011.75%209.5%2010.8438C11.0312%209.9375%2012%208.3125%2012%206.5ZM11.0312%2011.625C9.90625%2012.5%208.5%2013%207%2013C3.40625%2013%200.5%2010.0938%200.5%206.5C0.5%202.9375%203.40625%200%207%200C10.5625%200%2013.5%202.9375%2013.5%206.5C13.5%208.03125%2012.9688%209.4375%2012.0938%2010.5625L16.2812%2014.7188C16.5625%2015.0312%2016.5625%2015.5%2016.2812%2015.7812C15.9688%2016.0938%2015.5%2016.0938%2015.2188%2015.7812L11.0312%2011.625Z%22%20fill%3D%22%23492728%22%2F%3E%3C%2Fsvg%3E');
                    background-size: contain;
                    background-repeat: no-repeat;
                }

                & input {
                    @include txt-12-20R;

                    color: var(--emu-common-colors-dark-brown-500);
                    border: 1px solid var(--emu-common-colors-dark-brown-500);
                    border-radius: 6px;
                    letter-spacing: 1.8px;
                    text-transform: uppercase;
                    padding: 12px 16px 12px 36px;
                    width: 100%;

                    &:hover,
                    &:active,
                    &:focus {
                        outline: 1px solid var(--emu-common-colors-light-blue-110);
                    }
                }
            }

            &Submit {
                width: 50px;
            }

            &Tags {
                display: flex;
                gap: 12px;
                flex-wrap: wrap;

                &:has(div) {
                    margin-top: 22px;
                }

                & div {
                    @include txt-12-16R;

                    background-color:var(--emu-common-colors-grey-120);
                    border-radius: 6px;
                    padding: 6px 12px;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    gap: 10px;
                    color: var(--emu-common-colors-black);
                    letter-spacing: 1.8px;
                    text-transform: uppercase;

                    & span {
                        flex: 1;
                    }
                }
            }

            &NS {
                display: inline-block;
                width: fit-content;
            }
        }

        &Submit {
            color: transparent;
            padding: 14px 17px;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;
            background-color: var(--emu-common-colors-dark-brown-500);
            border-radius: 6px;
            position: relative;
            display: inline-block;

            &:hover,
            &:active,
            &:focus {
                outline: 1px solid var(--emu-common-colors-light-blue-110);
            }

            &:disabled {
                background-color: var(--emu-common-colors-grey-140);
            }

            & span {
                position: relative;
                display: inline-block;
                width: 16px;
                height: 16px;

                &::before {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2017%2017%22%20fill%3D%22currentColor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%206.5C12%204.71875%2011.0312%203.09375%209.5%202.1875C7.9375%201.28125%206.03125%201.28125%204.5%202.1875C2.9375%203.09375%202%204.71875%202%206.5C2%208.3125%202.9375%209.9375%204.5%2010.8438C6.03125%2011.75%207.9375%2011.75%209.5%2010.8438C11.0312%209.9375%2012%208.3125%2012%206.5ZM11.0312%2011.625C9.90625%2012.5%208.5%2013%207%2013C3.40625%2013%200.5%2010.0938%200.5%206.5C0.5%202.9375%203.40625%200%207%200C10.5625%200%2013.5%202.9375%2013.5%206.5C13.5%208.03125%2012.9688%209.4375%2012.0938%2010.5625L16.2812%2014.7188C16.5625%2015.0312%2016.5625%2015.5%2016.2812%2015.7812C15.9688%2016.0938%2015.5%2016.0938%2015.2188%2015.7812L11.0312%2011.625Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 16px;
                    height: 16px;
                    top: 0;
                    left: 0;
                }
            }
        }

        &Concern {
            @include txt-12-20R;

            color: var(--emu-common-colors-dark-brown-500);
            cursor: pointer;
            background-color: transparent;
            border: 1px solid var(--emu-common-colors-dark-brown-500);
            padding: 12px 16px;
            display: flex;
            position: relative;
            letter-spacing: 1.8px;
            text-transform: uppercase;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;

            &--open {
                & .emu-icl-feed__searchArrowD {
                    transform: rotate(180deg);
                }
            }

            &:hover,
            &:active,
            &:focus {
                outline: 1px solid var(--emu-common-colors-light-blue-110);
            }

            &:has(+ .emu-icl-feed__searchContSelect.emu-icl-feed__searchContSelect--none) {
                border-bottom-left-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

        &Close {
            width: 12px;
            height: 12px;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Ingredients / Icon'%3E%3Cpath id='Icon' fill-rule='evenodd' clip-rule='evenodd' d='M0.96967 0.96967C1.26256 0.676777 1.73744 0.676777 2.03033 0.96967L6 4.93934L9.96967 0.96967C10.2626 0.676777 10.7374 0.676777 11.0303 0.96967C11.3232 1.26256 11.3232 1.73744 11.0303 2.03033L7.06066 6L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L6 7.06066L2.03033 11.0303C1.73744 11.3232 1.26256 11.3232 0.96967 11.0303C0.676777 10.7374 0.676777 10.2626 0.96967 9.96967L4.93934 6L0.96967 2.03033C0.676777 1.73744 0.676777 1.26256 0.96967 0.96967Z' fill='%23000000' stroke='%23FAF6F5' stroke-miterlimit='10' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

        &ArrowD {
            width: 15px;
            height: 15px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.2112 4.68036C14.3693 4.83442 14.458 5.04327 14.458 5.26102C14.458 5.47877 14.3693 5.68761 14.2112 5.84168L8.58625 11.3196C8.42805 11.4735 8.21359 11.56 7.99 11.56C7.76641 11.56 7.55195 11.4735 7.39375 11.3196L1.76875 5.84168C1.61971 5.68592 1.53857 5.47989 1.54243 5.26702C1.54628 5.05414 1.63484 4.85104 1.78943 4.70049C1.94401 4.54994 2.15257 4.4637 2.37116 4.45995C2.58975 4.45619 2.8013 4.53521 2.96125 4.68036L7.99 9.57766L13.0188 4.68036C13.177 4.52648 13.3914 4.44005 13.615 4.44005C13.8386 4.44005 14.053 4.52648 14.2112 4.68036V4.68036Z' fill='%23492728' stroke='white'/%3E%3C/svg%3E%0A");
        }

        &Clinic {
            position: relative;
            flex: 1;
            min-width: 135px;
            padding: 12px 16px 12px 28px;

            @include mq($emu-semantic-sizing-breakpoints-large) {
                min-width: 130px;
            }

            &:before {
                content: '';
                z-index: 10;
                background-image: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2017%2017%22%20fill%3D%22currentColor%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12%206.5C12%204.71875%2011.0312%203.09375%209.5%202.1875C7.9375%201.28125%206.03125%201.28125%204.5%202.1875C2.9375%203.09375%202%204.71875%202%206.5C2%208.3125%202.9375%209.9375%204.5%2010.8438C6.03125%2011.75%207.9375%2011.75%209.5%2010.8438C11.0312%209.9375%2012%208.3125%2012%206.5ZM11.0312%2011.625C9.90625%2012.5%208.5%2013%207%2013C3.40625%2013%200.5%2010.0938%200.5%206.5C0.5%202.9375%203.40625%200%207%200C10.5625%200%2013.5%202.9375%2013.5%206.5C13.5%208.03125%2012.9688%209.4375%2012.0938%2010.5625L16.2812%2014.7188C16.5625%2015.0312%2016.5625%2015.5%2016.2812%2015.7812C15.9688%2016.0938%2015.5%2016.0938%2015.2188%2015.7812L11.0312%2011.625Z%22%20fill%3D%22%23492728%22%2F%3E%3C%2Fsvg%3E);
                background-repeat: no-repeat;
                background-size: contain;
                width: 12px;
                height: 12px;
                display: inline-block;
                position: absolute;
                top: 12px;
                left: 22px;
            }
        }

        &NewSearch {
            @include txt-20-20R;

            color: var(--emu-common-colors-black);
            font-variant: all-small-caps;
            text-decoration: underline;
            display: flex;
            align-items: center;
            gap: 8px;

            &:hover,
            &:active,
            &:focus {
                text-decoration: underline;
            }

            &:focus-visible,
            &:focus {
                outline: 1px solid var(--emu-common-colors-light-blue-110);
            }

            & .emu-icl-feed__searchArrowD {
                transform: rotate(90deg);
            }
        }
    }
}
